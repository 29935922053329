import { CircularProgress, Grid, Typography } from "@mui/material";
import * as React from "react";

export default function PageLoading() {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="column"
        style={{ height: 130 }}
      >
        <Typography variant="h3" color="primary">
          {window.location.hostname}
        </Typography>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}
