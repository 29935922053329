import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Help = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" height="calc(100vh - 70px)">
      <Typography variant="h5" align="center" style={{ marginBottom: 24 }}>
        Unter Bearbeitung
      </Typography>
      <Box alignSelf="center">
        <Button color="secondary" size="large" onClick={() => window.close()}>
          {t("common:buttons.close")}
        </Button>
      </Box>
    </Box>
  );
};

export default Help;
