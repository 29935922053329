import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { HeaderProvider } from "components/HeaderContext";
import Layout from "components/Layout/Layout";
import LayoutLoading from "components/Layout/LayoutLoading";
import { PermissionProvider } from "components/PermissonContext";
import { CUSTOMER_VERSION } from "config/constants";
import i18n from "i18n";
import { lazy, Suspense, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router-dom";
import { EditorProvider } from "views/Editor/components/EditorContext";
import Help from "views/Help";
import "./App.css";

const PageNotFound = lazy(() => import("views/PageNotFound"));
const ItemList = lazy(() => import("views/Item/ItemList"));
const ItemCreate = lazy(() => import("views/Item/ItemCreate"));
const ItemModify = lazy(() => import("views/Item/ItemModify"));
const ItemDetails = lazy(() => import("views/Item/ItemDetails"));
const EditorView = lazy(() => import("views/Editor/EditorView"));

function App() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const version = query.get("version");

  const customerVersion = localStorage.getItem("editor-version") || CUSTOMER_VERSION;

  if (version) {
    localStorage.setItem("editor-version", version);
  } //"gumtech", "lilowersum", "promera"

  useEffect(() => {
    if (window.location.hostname.includes("flaechenkalkulator")) {
      i18n.changeLanguage("de");
    } else if (window.location.hostname.includes("spacecalculator")) {
      i18n.changeLanguage("en");
    } else if (window.location.hostname.includes("teruletkalkulator")) {
      i18n.changeLanguage("hu");
    }
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdEbQIiAAAAAIdBnK5G34r6czGCqBqYwAjIFy2R">
      <HeaderProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Suspense fallback={<LayoutLoading />}>
            <EditorProvider>
              <PermissionProvider>
                <Layout>
                  <Helmet>
                    {customerVersion === "gumtech" && <title>Gum-tech</title>}
                    {customerVersion === "lilowersum" && <title>Lilowersum</title>}
                    {customerVersion === "promera" && <title>Promera</title>}
                  </Helmet>
                  <Routes>
                    <Route element={<PageNotFound />} />
                    <Route index element={<EditorView />} />
                    <Route path="/" element={<EditorView />} />
                    <Route path="items">
                      <Route path="create" element={<ItemCreate />} />
                      <Route path="list" element={<ItemList />} />
                      <Route path="modify" element={<ItemModify />} />
                      <Route path="details" element={<ItemDetails />} />
                    </Route>
                    <Route path="editor" element={<EditorView />} />
                    <Route path="help" element={<Help />} />
                  </Routes>
                </Layout>
              </PermissionProvider>
            </EditorProvider>
          </Suspense>
        </LocalizationProvider>
      </HeaderProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
