import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogTitle, IconButton } from "@mui/material";
import { COLORS } from "config/theme";
import React, { Dispatch, ReactNode, SetStateAction } from "react";

type Props = {
  title: ReactNode;
  setOpen?: Dispatch<SetStateAction<boolean>>;
};

const StyledDialogHeader = ({ title, setOpen }: Props) => {
  return (
    <DialogTitle sx={{ background: COLORS.lightBlue, textTransform: "uppercase", fontSize: "20px", fontWeight: "bold", color: COLORS.foskek }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>{title}</Box>
        {setOpen && (
          <IconButton color="primary" size="small" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </DialogTitle>
  );
};

export default StyledDialogHeader;
