import { EditorBorderType, TileCalculateData } from "../components/EditorContext";

export function calculateVerticalPieces(topMost: TileCalculateData, bottomMost: TileCalculateData, borderType: EditorBorderType) {
  let sidePcs = 0;
  let centerPcs = 0;
  if (
    borderType.bottom &&
    (bottomMost?.tile?.type?.includes("side_bottom") ||
      bottomMost?.tile?.type?.includes("corner_bottomright") ||
      bottomMost?.tile?.type?.includes("corner_bottomleft"))
  ) {
    sidePcs++;
  }

  if (
    borderType.top &&
    (topMost?.tile?.type?.includes("side_top") || topMost?.tile?.type?.includes("corner_topright") || topMost?.tile?.type?.includes("corner_topleft"))
  ) {
    sidePcs++;
  }

  if (topMost && bottomMost) {
    centerPcs = bottomMost?.y - sidePcs - (topMost.y - 1);

    if (topMost?.child) {
      if ((topMost?.child.position.rotation === 0 || topMost?.child.position.rotation === 180) && topMost.tile.type === "center") {
        centerPcs--;
        sidePcs++;
      }
    }
    if (bottomMost?.child) {
      if ((bottomMost?.child.position.rotation === 0 || bottomMost?.child.position.rotation === 180) && bottomMost.tile.type === "center") {
        centerPcs--;
        sidePcs++;
      }
    }
  }

  return { sidePcs, centerPcs };
}
