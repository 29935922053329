import { TileCalculateData } from "../components/EditorContext";
import { TileData } from "../EditorNew";

export function getBottommostTile(tiles: TileData[][]) {
  const rowLength = tiles[0]?.length || tiles[1]?.length;
  let bottomMostTile: TileCalculateData = null!;
  for (let i = tiles?.length - 2; i >= 0; i--) {
    for (let j = 0; j < rowLength - 1; j++) {
      if (!tiles[i][j]?.inactive && tiles[i][j]?.transform?.color && !bottomMostTile) {
        bottomMostTile = { tile: tiles[i][j], y: i + 1, x: j + 1 };
        break;
      }
      if (bottomMostTile) {
        break;
      }
    }
  }

  let max = 0;
  let tile: TileData = null!;
  tiles[tiles?.length - 1]?.forEach(value => {
    if (value.position.posY > max) {
      tile = value;
      max = value.position.posY;
    }
  });

  let child: TileData = null!;
  let parent: TileCalculateData = null!;
  if (tile?.position.posY > bottomMostTile?.tile?.position?.posY) {
    child = tile;
    for (let i = tiles?.length - 2; i >= 0; i--) {
      for (let j = 0; j < rowLength - 1; j++) {
        if (tiles[i][j]?.id === child?.parentId && !parent) {
          parent = { tile: tiles[i][j], y: i + 1, x: j + 1 };
          break;
        }
        if (parent) {
          break;
        }
      }
    }
  }

  if (parent) {
    return { ...parent, child };
  } else {
    return { ...bottomMostTile, child };
  }
}
