import { TileCalculateData } from "../components/EditorContext";
import { TileData } from "../EditorNew";

export function getLeftmostTile(tiles: TileData[][]) {
  const rowLength = tiles[0]?.length || tiles[1]?.length;
  let leftMostTile: TileCalculateData = null!;
  for (let j = 0; j < rowLength - 1; j++) {
    for (let i = 0; i < tiles?.length - 1; i++) {
      if (!tiles[i][j]?.inactive && tiles[i][j]?.transform?.color && !leftMostTile) {
        leftMostTile = { tile: tiles[i][j], y: i + 1, x: j + 1 };
        break;
      }
      if (leftMostTile) {
        break;
      }
    }
  }

  let min = Number.MAX_VALUE;
  let tile: TileData = null!;
  tiles[tiles?.length - 1]?.forEach(value => {
    if (value.position.posX < min) {
      tile = value;
      min = value.position.posX;
    }
  });

  let child: TileData = null!;
  let parent: TileCalculateData = null!;
  if (tile?.position.posX < leftMostTile?.tile?.position?.posX) {
    child = tile;
    for (let j = 0; j < rowLength - 1; j++) {
      for (let i = 0; i < tiles?.length - 1; i++) {
        if (tiles[i][j]?.id === child?.parentId && !parent) {
          parent = { tile: tiles[i][j], y: i + 1, x: j + 1 };
          break;
        }
        if (parent) {
          break;
        }
      }
    }
  }

  if (parent) {
    return { ...parent, child };
  } else {
    return { ...leftMostTile, child };
  }
}
