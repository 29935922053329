import axios from "config/axios";
import { GenericResponse } from "shared/common";
import { SnapshotData } from "shared/types";

const ENDPOINT = "/editor-snapshot";

export const saveNewSnapshot = (data: string) =>
  axios.post<GenericResponse<SnapshotData>>(`${ENDPOINT}/create`, {
    param: { data },
  });

export const saveSnapshot = (trackingCode: string, data: string) =>
  axios.post<GenericResponse<SnapshotData>>(`${ENDPOINT}/modify`, {
    param: { trackingCode, data },
  });

export const getByTrackingCode = (trackingCode: string) =>
  axios.post<GenericResponse<SnapshotData>>(`${ENDPOINT}/get-by-tracking`, {
    param: trackingCode,
  });
