export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "https://gumtech-backend.dev.promera.systems";
// export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "https://editor.gum-tech.de/api";

export const DEV_BACKEND_URLS = ["http://localhost:3000", "https://gumtech-backend.dev.promera.systems"];

export const CUSTOMER_VERSION: string = process.env.REACT_APP_CUSTOMER_VERSION || "gumtech"; //"lilowersum"; //envből is jöhetne

export const WEBSOCKET_URL = "ws://192.168.88.107:8089/ws";

export const AUTH_TOKEN_KEY = "@rap_software_auth_token";
export const PROFILE_TYPE_KEY = "@rap_software_profile_type";

export const ICON_SIZE = 32.5;

export const ITEM_TYPES = ["NORMAL", "PUZZLE", "GRID"];

export const itemVariantTypes = {
  center: "CENTER", //0
  side: "SIDE", //1
  corner: "CORNER", //2
  sideAlt: "SIDE_ALT", //3
  cornerNoEar: "CORNER_NO_EAR", //4
  sideCutLeft: "SIDE_CUT_LEFT", //5
  sideCutLeftAlt: "SIDE_CUT_LEFT_ALT", //6
  sideCutRight: "SIDE_CUT_RIGHT", //7
  sideCutRightAlt: "SIDE_CUT_RIGHT_ALT", //8
  cornerInclined: "CORNER_INCLINED", //9
  cornerInclinedNoEar: "CORNER_INCLINED_NO_EAR", //10
  sideInclined: "SIDE_INCLINED", //11
  sideInclinedAlt: "SIDE_INCLINED_ALT", //12
  sideInclinedCutLeft: "SIDE_INCLINED_CUT_LEFT", //13
  sideInclinedCutLeftAlt: "SIDE_INCLINED_CUT_LEFT_ALT", //14
  sideInclinedCutRight: "SIDE_INCLINED_CUT_RIGHT", //15
  sideInclinedCutRightAlt: "SIDE_INCLINED_CUT_RIGHT_ALT", //16
  innerCornerRightBottom: "INNER_CORNER_RIGHT_BOTTOM",
  innerCornerLeftTop: "INNER_CORNER_LEFT_TOP",
  innerCornerRightTop: "INNER_CORNER_RIGHT_TOP",
  innerCornerLeftBottom: "INNER_CORNER_LEFT_BOTTOM",
  innerCornerRightBottomInclined: "INNER_CORNER_RIGHT_BOTTOM_INCLINED",
  innerCornerLeftTopInclined: "INNER_CORNER_LEFT_TOP_INCLINED",
  innerCornerRightTopInclined: "INNER_CORNER_RIGHT_TOP_INCLINED",
  innerCornerLeftBottomInclined: "INNER_CORNER_LEFT_BOTTOM_INCLINED",
};

export const MOUSE_MODES = ["DRAG", "COLOR", "DELETE", "INACTIVE"];

export const PLACEMENT_TYPES = ["CENTER", "SIDE", "CORNER", "SIDE_CUT", "CORNER_CUT"];

export const PUT_DOWN_TYPES = ["DEFAULT", "OFFSET"];

export const PUT_DOWN_STRATEGY_TYPES = ["INSIDE", "OUTSIDE"];

export const AREA_SHAPE_TYPES = ["SQUARE", "CIRCLE", "TRIANGLE"];

export const LANGUAGES = ["de", "en"];

// the method of aligning the real area over the plan base area
export const FINAL_PLACEMENT_TYPES = ["CENTER", "HORIZONTAL", "VERTICAL"];

// in n times of the actual size
export const ZOOM_LIMIT = 5;
export const ZOOM_FLOOR = 0.1; //lower limit
export const ZOOM_RATE = 1.05;

// maximum amount of edit steps avaible for the undo fuction
export const HISTORY_STEP_LIMIT = 50;

// line size limits (grid, mouse tracking, etc.)
export const LINE_SIZE_MIN = 1;
export const LINE_SIZE_MAX = 10;

// canvas unit size for 1m real world size (px)
export const pxPerMeter = 75;

// maximum area size (m)
export const MAX_AREA_SIZE = 30;
