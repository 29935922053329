import { Box } from "@mui/material";
import { useHeader } from "components/HeaderContext";
import { BACKEND_URL, CUSTOMER_VERSION } from "config/constants";
import { COLORS } from "config/theme";
import { useState } from "react";
import EditorSettingsDialog from "views/Editor/components/EditorSettingsDialog";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const { headerButtons, toolbarButtons } = useHeader();
  const [optionsOpen, setOptionsOpen] = useState(false);

  const customerVersion = localStorage.getItem("editor-version") || CUSTOMER_VERSION;

  return (
    <>
      <EditorSettingsDialog open={optionsOpen} setOpen={setOptionsOpen} />
      <Box display="flex" flexDirection="column" justifyContent="flex-start" width="100%" height="auto">
        {/*HEADER*/}
        <Box display="flex" alignItems="center" width="100%" height="66px" sx={{ background: COLORS.greyBack }}>
          <Box display="flex" width="350px" minWidth="350px">
            {customerVersion === "lilowersum" && (
              <img
                src={process.env.PUBLIC_URL + "/lilowersum_logo.svg"}
                alt="Lilowersum"
                style={{ marginLeft: 4, height: 60, cursor: "pointer" }}
                onClick={() => window.location.replace("https://www.lilowersum.de/")}
              />
            )}
            {customerVersion === "gumtech" && (
              <img
                src={process.env.PUBLIC_URL + "/logo.svg"}
                alt="Gum-Tech"
                style={{ marginLeft: "28px", cursor: "pointer" }}
                onClick={() => window.location.replace("https://gum-tech.de/")}
              />
            )}
            {customerVersion === "promera" && (
              <img
                src={process.env.PUBLIC_URL + "Promera_logo.png"}
                alt="Promera"
                style={{ marginLeft: "28px", width: "200px", cursor: "pointer" }}
                onClick={() => window.location.replace("https://www.promeram.com/articles/how-to-make-extra-money/")}
              />
            )}
          </Box>
          <Box display="flex" width="calc(100% - 350px)">
            {headerButtons}
          </Box>
        </Box>
        {/* Toolbar */}
        {toolbarButtons}
        {/*MainView*/}
        <Box display="flex" justifyContent="center" width="100%" height="auto" sx={{ minHeight: 66, background: COLORS.greyYetAnother }}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
