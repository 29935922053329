import { ArrowDropDown } from "@mui/icons-material";
import { Box, InputAdornment, Popover, TextField } from "@mui/material";
import { ReactNode, useState } from "react";
import { ChromePicker } from "react-color";
import { useEditorData } from "./EditorContext";

type Props = {
  title: ReactNode;
  target: string;
};

const ColorPicker = ({ title, target }: Props) => {
  const { editorOptions, modifyEditorOption } = useEditorData();

  const data = () => {
    switch (target) {
      case "planBorderColor":
        return editorOptions.planBorderColor;
      case "gridColor":
        return editorOptions.gridColor;
      case "trackingColor":
        return editorOptions.trackingColor;
      case "trackingTextColor":
        return editorOptions.trackingTextColor;
      case "backgroundColor":
        return editorOptions.backgroundColor;
      default:
        return undefined;
    }
  };

  const setValue = (value: string) => {
    switch (target) {
      case "planBorderColor":
        modifyEditorOption({ planBorderColor: value });
        break;
      case "gridColor":
        modifyEditorOption({ gridColor: value });
        break;
      case "trackingColor":
        modifyEditorOption({ trackingColor: value });
        break;
      case "trackingTextColor":
        modifyEditorOption({ trackingTextColor: value });
        break;
      case "backgroundColor":
        modifyEditorOption({ backgroundColor: value });
        break;
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <TextField
        sx={{ maxWidth: 250 }}
        label={title}
        value={data()}
        onClick={handleClick}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box sx={{ backgroundColor: `${data()}`, border: "1px solid grey", borderRadius: "50%", width: "24px", height: "24px" }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDown />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ChromePicker
          color={data()}
          onChangeComplete={(color: any) => {
            setValue(color.hex);
          }}
          // colors={COLOR_ARRAY}
        />
      </Popover>
    </>
  );
};

export default ColorPicker;
