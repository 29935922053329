import { Checkbox, FormControlLabel } from "@mui/material";
import { ReactNode } from "react";
import { useEditorData } from "./EditorContext";

type Props = {
  title: ReactNode;
  target: string;
};

const SettingsCheckBox = ({ title, target }: Props) => {
  const { editorOptions, modifyEditorOption } = useEditorData();

  const data = () => {
    switch (target) {
      case "planBorder":
        return editorOptions.planBorder;
      case "showGrid":
        return editorOptions.showGrid;
      case "tracking":
        return editorOptions.tracking;
      case "trackingText":
        return editorOptions.trackingText;
      case "finalOffset":
        return editorOptions.finalOffset;
      default:
        return undefined;
    }
  };

  const setValue = (value: boolean) => {
    switch (target) {
      case "planBorder":
        modifyEditorOption({ planBorder: value });
        break;
      case "showGrid":
        modifyEditorOption({ showGrid: value });
        break;
      case "tracking":
        modifyEditorOption({ tracking: value });
        break;
      case "trackingText":
        modifyEditorOption({ trackingText: value });
        break;
      case "finalOffset":
        modifyEditorOption({ finalOffset: value });
        break;
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={data()} onChange={e => setValue(e.target.checked)} onClick={() => setValue(!data())} />}
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
        },
      }}
      label={title}
    />
  );
};

export default SettingsCheckBox;
