import { createTheme } from "@mui/material/styles";

export const COLORS = {
  white: "#ffffff",
  water: "#00A1C2",
  black: "#1D1B1B",
  darkPurple: "#3F1263",
  darkGrey: "#3F3951",
  purple: "#7A5C96",
  blue: "#06ACAC",
  blueDefault: "#376ED8",
  oldBlue: "#717B8E",
  mediumBlack: "#213740",
  foskek: "#265264",
  blueHover: "#1d4455",
  redText: "#d50000",
  redHover: "#ffeaea",
  lightBlue: "#9babb6",
  stepperBlue: "#bccdd9",
  // reduce number of grey shades in future - design ?!
  grey: "#626262",
  greyA: "#e3e6ea",
  greyBase: "#ecedf1",
  greyHover: "#e5e8eb",
  greyBack: "#f4f3f6",
  greyBorder: "#97abb4",
  greyLightBorder: "#e0e6e9",
  greyHoverAnother: "#7d97a2",
  greyAnother: "#e9eef2",
  greyYetAnother: "#e9ebef",
  greyLine: "#e0e8ed",
  greyBorderAnother: "#979797",
  greyNoLine: "#dbe1e5",
};

export const COLOR_ARRAY = [
  "#00A1C2",
  "#1D1D1B",
  "#3F1263",
  "#3F3951",
  "#7A5C96",
  "#06ACAC",
  "#376ED8",
  "#717B8E",
  "#213740",
  "#265264",
  "#626262",
  "#FFFFFF",
];

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 917,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#265264",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    body2: {
      fontWeight: "bold",
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: "2px",
          backgroundColor: COLORS.foskek,
          fontFamily: "Montserrat",
          fontWeight: "600",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: COLORS.foskek,
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
            backgroundColor: COLORS.foskek,
          },
          "&:focus": {
            outline: "none",
          },
        },
        outlined: {
          borderRadius: "2px",
          borderColor: COLORS.foskek,
          fontFamily: "Montserrat",
          fontWeight: "600",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: COLORS.foskek,
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
            borderColor: COLORS.foskek,
          },
          "&:focus": {
            outline: "none",
          },
        },
        text: {
          borderRadius: "2px",
          fontFamily: "Montserrat",
          fontWeight: "600",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
          },
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "4px",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          input: {
            "&[type=number]": {
              "-moz-appearance": "textfield",
            },
            "&::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "&::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: COLORS.lightBlue,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "2px",
          borderColor: COLORS.greyBorder,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "none",
        fullWidth: true,
        variant: "outlined",
        size: "small",
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

export default theme;
