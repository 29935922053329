import { ItemVariant } from "shared/types";
import { TileData } from "../EditorNew";

export function getTileBounds(
  tile: TileData,
  centerVariant: ItemVariant | undefined,
  cornerVariant: ItemVariant | undefined,
  borderVariant: ItemVariant | undefined,
) {
  // center item
  if (tile.type === "center") {
    return {
      x1: tile.position.posX - (tile.transform.sizeX - ((centerVariant?.widthWithTab || 0) - (centerVariant?.width || 0))) / 2,
      y1: tile.position.posY - (tile.transform.sizeY - ((centerVariant?.lengthWithTab || 0) - (centerVariant?.length || 0))) / 2,
      x2: tile.position.posX + (tile.transform.sizeX - ((centerVariant?.widthWithTab || 0) - (centerVariant?.width || 0))) / 2,
      y2: tile.position.posY + (tile.transform.sizeY - ((centerVariant?.lengthWithTab || 0) - (centerVariant?.length || 0))) / 2,
    };
  }
  // corner item
  if (tile.type.startsWith("corner") || tile.type.startsWith("inner_corner")) {
    if (tile.position.rotation === 0 || tile.position.rotation === 180) {
      return {
        x1: tile.position.posX - (tile.transform.sizeX - ((cornerVariant?.widthWithTab || 0) - (cornerVariant?.width || 0))) / 2,
        y1: tile.position.posY - (tile.transform.sizeY - ((cornerVariant?.lengthWithTab || 0) - (cornerVariant?.length || 0))) / 2,
        x2: tile.position.posX + (tile.transform.sizeX - ((cornerVariant?.widthWithTab || 0) - (cornerVariant?.width || 0))) / 2,
        y2: tile.position.posY + (tile.transform.sizeY - ((cornerVariant?.lengthWithTab || 0) - (cornerVariant?.length || 0))) / 2,
      };
    } else {
      return {
        x1: tile.position.posX - (tile.transform.sizeY - ((cornerVariant?.lengthWithTab || 0) - (cornerVariant?.length || 0))) / 2,
        y1: tile.position.posY - (tile.transform.sizeX - ((cornerVariant?.widthWithTab || 0) - (cornerVariant?.width || 0))) / 2,
        x2: tile.position.posX + (tile.transform.sizeY - ((cornerVariant?.lengthWithTab || 0) - (cornerVariant?.length || 0))) / 2,
        y2: tile.position.posY + (tile.transform.sizeX - ((cornerVariant?.widthWithTab || 0) - (cornerVariant?.width || 0))) / 2,
      };
    }
  }
  // side vertical
  if (tile.position.rotation === undefined || tile.position.rotation === 0 || tile.position.rotation === 180) {
    return {
      x1: tile.position.posX - (tile.transform.sizeX - ((borderVariant?.widthWithTab || 0) - (borderVariant?.width || 0))) / 2,
      y1: tile.position.posY - (tile.transform.sizeY - ((borderVariant?.lengthWithTab || 0) - (borderVariant?.length || 0))) / 2,
      x2: tile.position.posX + (tile.transform.sizeX - ((borderVariant?.widthWithTab || 0) - (borderVariant?.width || 0))) / 2,
      y2: tile.position.posY + (tile.transform.sizeY - ((borderVariant?.lengthWithTab || 0) - (borderVariant?.length || 0))) / 2,
    };
  }
  // side horizontal
  return {
    x1: tile.position.posX - (tile.transform.sizeY - ((borderVariant?.lengthWithTab || 0) - (borderVariant?.length || 0))) / 2,
    y1: tile.position.posY - (tile.transform.sizeX - ((borderVariant?.widthWithTab || 0) - (borderVariant?.width || 0))) / 2,
    x2: tile.position.posX + (tile.transform.sizeY - ((borderVariant?.lengthWithTab || 0) - (borderVariant?.length || 0))) / 2,
    y2: tile.position.posY + (tile.transform.sizeX - ((borderVariant?.widthWithTab || 0) - (borderVariant?.width || 0))) / 2,
  };
}
