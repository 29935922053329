import { useQuery } from "@tanstack/react-query";
import {
  AREA_SHAPE_TYPES,
  CUSTOMER_VERSION,
  FINAL_PLACEMENT_TYPES,
  HISTORY_STEP_LIMIT,
  ITEM_TYPES,
  itemVariantTypes,
  MOUSE_MODES,
  PLACEMENT_TYPES,
  PUT_DOWN_STRATEGY_TYPES,
  PUT_DOWN_TYPES,
  pxPerMeter,
  ZOOM_LIMIT,
} from "config/constants";
import queryClient from "config/query";
import { COLORS } from "config/theme";
import { ceil, isEmpty, round } from "lodash";
import { useSnackbar } from "notistack";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { getItemById } from "shared/network/item-api";
import { OfferVariant } from "shared/network/offer-api";
import { getByTrackingCode, saveNewSnapshot, saveSnapshot } from "shared/network/snapshot-api";
import { ColorType, ItemVariant } from "shared/types";
import { TileData } from "../EditorNew";
import { calculateHorizontalPieces } from "../functions/calculateHorizontalPieces";
import { calculateVerticalPieces } from "../functions/calculateVerticalPieces";
import { getBottommostTile } from "../functions/getBottommostTile";
import { getLeftmostTile } from "../functions/getLeftmostTile";
import { getRightmostTile } from "../functions/getRightmostTile";
import { getTileArea } from "../functions/getTileArea";
import { getTileBounds } from "../functions/getTileBounds";
import { getTopmostTile } from "../functions/getTopmostTile";

export type EditorContextType = {
  itemId?: number;
  itemName: string;
  mouseMode: string;
  tilePlacementType: string;
  activeColor: ColorType | null;
  putDownType: string;
  borderType: EditorBorderType;
  strategy: string;
  areaShape: string;
  thickness: number;
  width: number;
  height: number;
  zoomScale: { x: number; y: number };
  ref: any;
  avaibleThickness: number[];
  avaibleVariants: Map<number, ItemVariant[]> | null;
  baseColor: ColorType | null;
  availableColors: ColorType[];
  cutSize: number;
  tileSize: TileSize;
  sideSize: TileSize;
  cornerSize: TileSize;
  tileData: TileData[][];
  saveOpen: boolean;
  saveAsOpen: boolean;
  loadOpen: boolean;
  createOpen: boolean;
  editorOpen: boolean;
  offerOpen: "OFFER" | "PDF" | null;
  trackingCode: string;
  offerStats: OfferStats | null;
  editorOptions: EditorOptions;
  itemType: string;
  variantPickerData: VariantPickerDataType;
  ghostTile?: TileData;
  refresh: boolean;
  editHistory: any[];
  sideInclinedType: any;
  setEditHistory: (editHistory: any) => any;
  setRefresh: (refresh: boolean) => void;
  setMouseMode: (mode: string) => void;
  setTilePlacementType: (type: string) => void;
  setActiveColor: (color: ColorType | null) => void;
  setBaseColor: (color: ColorType | null) => void;
  setPutDownType: (type: string) => void;
  setBorderType: (border: Partial<EditorBorderType>, needHistory: boolean) => void;
  setStrategy: (strategy: string, needHistory: boolean) => void;
  setAreaShape: (shape: string) => void;
  setThickness: (value: number) => void;
  setAreaSize: (width: number, height: number, needHistory: boolean) => void;
  setWidth: (value: number) => void;
  setHeight: (value: number) => void;
  setZoomScale: (value: { x: number; y: number }) => void;
  resetCanvasMove: (customWidth?: number, customHeight?: number) => void;
  setTileData: (addToHistory: boolean, data: TileData[][]) => void;
  hasHistory: () => boolean;
  historyStepBack: () => void;
  saveData: (forceNew: boolean, openModal: boolean) => Promise<string>;
  loadData: (trackingCode: string) => void;
  setSaveOpen: (value: boolean) => void;
  setSaveAsOpen: (value: boolean) => void;
  setLoadOpen: (value: boolean) => void;
  setCreateOpen: (value: boolean) => void;
  setOfferOpen: (value: "OFFER" | "PDF" | null) => void;
  setEditorOpen: (value: boolean) => void;
  selectItem: (id: number) => void;
  startOfferCreate: (type: "OFFER" | "PDF") => void;
  resetEditor: (values?: {
    borderType?: EditorBorderType;
    width?: number;
    height?: number;
    strategy?: string;
    putDownType?: string;
    itemId?: number;
    thickness?: number;
    color?: ColorType;
    sideInclinedType?: "NORMAL" | "INCLINED";
  }) => void;
  modifyEditorOption: (values: Partial<EditorOptions>) => void;
  getFinalOffset: (isHorizontal: boolean) => boolean;
  resetEditorOptions: () => void;
  setVariantPickerData: (data: VariantPickerDataType) => void;
  setGhostTile: (newGhost?: TileData) => void;
  resetEditChanges: () => void;
};

type Props = {
  children: React.ReactNode;
};

export type TileSize = {
  width: number;
  height: number;
  widthWithTab?: number;
  heigthWithTab?: number;
};

export type EditorBorderType = {
  top?: boolean;
  left?: boolean;
  bottom?: boolean;
  right?: boolean;
  autofill?: boolean;
};

export type SaveState = {
  putDownType: string;
  borderType: EditorBorderType;
  width: number;
  height: number;
  baseColor: ColorType | null;
  strategy: string;
  itemId: string;
  tileData: TileData[][];
  areaShape?: string;
};

export type OfferStats = {
  allTileCount: number;
  mass: number;
  co2Saving: number;
  tileCountPerVariant: Map<string, VariantColorData[]>;
  realWidth: number;
  realHeight: number;
  realM2: number;
};

export type VariantCount = {
  variant?: ItemVariant;
  color: ColorType;
  count: number;
};

export type EditorOptions = {
  planBorder: boolean;
  planBorderColor: string;
  planBorderSize: number;
  showGrid: boolean;
  gridColor: string;
  gridSize: number;
  tracking: boolean;
  trackingColor: string;
  trackingSize: number;
  trackingText: boolean;
  trackingTextColor: string;
  finalOffset: boolean;
  finalOffsetType: string;
  backgroundColor: string;
};

export type VariantPickerDataType = {
  centerColor?: ColorType;
  sideColor?: ColorType;
  cornerColor?: ColorType;
  centerCut: boolean;
  sideCut: boolean;
  cornerCut: boolean;
};

export type VariantColorData = {
  variant?: ItemVariant;
  color?: ColorType;
  count: number;
};

export type TileCalculateData = {
  tile: TileData;
  y: number;
  x: number;
  child?: TileData | null;
} | null;

export type BoundingBox = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

const variantPickerDataDefault: VariantPickerDataType = {
  centerCut: false,
  sideCut: false,
  cornerCut: false,
};

// default values for all editor options
const defaultEditorOptions: EditorOptions = {
  planBorder: true,
  planBorderColor: "#745D99",
  planBorderSize: 3,
  showGrid: true,
  gridColor: COLORS.black,
  gridSize: 1,
  tracking: true,
  trackingColor: COLORS.blueDefault,
  trackingSize: 1,
  trackingText: true,
  trackingTextColor: COLORS.black,
  finalOffset: true,
  finalOffsetType: "CENTER",
  backgroundColor: "#FFFFFF",
};

export const EditorContext = createContext({} as EditorContextType);

export function useEditorData() {
  const context = useContext(EditorContext);

  return context;
}

export const EditorProvider = ({ children }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const defTrackingCode = query.get("code");

  // the currently selected item id
  const [itemId, setItemId] = useState<number | undefined>(undefined);
  // the name of the currently selected item
  const [itemName, setItemNameState] = useState<string>("");

  const customerVersion = localStorage.getItem("editor-version") || CUSTOMER_VERSION;

  const itemQuery = useQuery(
    ["itemQueryForContext", itemId, customerVersion],
    async () => {
      if (itemId) {
        const { data } = await getItemById(itemId);
        return data.item;
      }
      return Promise.reject();
    },
    {
      enabled: !!itemId,
      onSuccess: data => {
        // convert returned object to variant map & array
        let sizes = new Map<number, ItemVariant[]>();
        let avaibleThickness: number[] = [];
        Object.entries(data.variants).forEach((entry: any[]) => {
          sizes.set(Number.parseFloat(entry[0]), entry[1]);
          avaibleThickness.push(Number.parseFloat(entry[0]));
        });
        avaibleThickness.sort((a, b) => a - b);
        setAvaibleThickness(avaibleThickness);
        setAvaibleVariants(sizes);
        setAvailableColors(
          data.relItemColors
            ?.filter((value: any) => (customerVersion === "lilowersum" ? value.isLilowersum && value.isActive : value.isGumtech && value.isActive))
            ?.map((entry: any) => entry.color),
        );
        //setBaseColorState(data.relItemColors.find((entry: any) => entry.color.id === data.defaultColorId)?.color || null);

        setCutSize(data.cutSize);
        // for normal items
        let variant = sizes.get(avaibleThickness[0])?.find(entry => entry.type === itemVariantTypes.center); // center item

        setTileSize({
          width: Number.parseFloat(variant?.width.toString() || ""),
          height: Number.parseFloat(variant?.length.toString() || ""),
          widthWithTab: data.type !== "NORMAL" && variant?.widthWithTab ? Number.parseFloat(variant?.widthWithTab.toString() || "") : undefined,
          heigthWithTab: data.type !== "NORMAL" && variant?.lengthWithTab ? Number.parseFloat(variant?.lengthWithTab.toString() || "") : undefined,
        });

        // for puzzle items
        if (data.type === "PUZZLE") {
          let sideVariant = sizes.get(avaibleThickness[0])?.find(entry => entry.type === itemVariantTypes.side); // side item
          let cornerVariant = sizes.get(avaibleThickness[0])?.find(entry => entry.type === itemVariantTypes.corner); // corner item
          setSideSize({
            width: Number.parseFloat(sideVariant?.width.toString() || ""),
            height: Number.parseFloat(sideVariant?.length.toString() || ""),
            widthWithTab:
              data.type === "PUZZLE" && sideVariant?.widthWithTab ? Number.parseFloat(sideVariant?.widthWithTab.toString() || "") : undefined,
            heigthWithTab:
              data.type === "PUZZLE" && sideVariant?.lengthWithTab ? Number.parseFloat(sideVariant?.lengthWithTab.toString() || "") : undefined,
          });

          setCornerSize({
            width: Number.parseFloat(cornerVariant?.width.toString() || ""),
            height: Number.parseFloat(cornerVariant?.length.toString() || ""),
            widthWithTab:
              data.type === "PUZZLE" && cornerVariant?.widthWithTab ? Number.parseFloat(cornerVariant?.widthWithTab.toString() || "") : undefined,
            heigthWithTab:
              data.type === "PUZZLE" && cornerVariant?.lengthWithTab ? Number.parseFloat(cornerVariant?.lengthWithTab.toString() || "") : undefined,
          });

          if (areaShape === "SQUARE") {
            setBorderTypeState({
              left: borderType.left === undefined ? true : borderType.left,
              right: borderType.right === undefined ? true : borderType.right,
              top: borderType.top === undefined ? true : borderType.top,
              bottom: borderType.bottom === undefined ? true : borderType.bottom,
            });
          }
        }

        // set item type
        setItemType(data.type || "NORMAL");
        setItemNameState(data.name || "");
      },
    },
  );

  // cut size for center tiles at the border (when there is no border)
  const [cutSize, setCutSize] = useState<number>(0);
  // selected item type
  const [itemType, setItemType] = useState<string>(ITEM_TYPES[0]);
  // current action type on mouse click
  // default - none  [ color -> color tile, delete -> delete tile]
  const [mouseMode, setMouseModeState] = useState<string>(MOUSE_MODES[3]);
  // the current type of tile to be put down / recolored on mouse click - effective only if MOUSE_MODE is COLOR
  const [tilePlacementType, setTilePlacementTypeState] = useState<string>(PLACEMENT_TYPES[0]);
  // the current states of the variant pickers (color / type)
  const [variantPickerData, setVariantPickerDataState] = useState<VariantPickerDataType>(variantPickerDataDefault);
  // currently selected color in editor
  // default: null
  const [activeColor, setActiveColorState] = useState<ColorType | null>(null);
  // the base color for initial tile rendering
  const [baseColor, setBaseColorState] = useState<ColorType | null>(null);
  // currently selected put-down method type
  // default: DEFAULT
  const [putDownType, setPutDownTypeState] = useState<string>(PUT_DOWN_TYPES[0]);
  const [sideInclinedType, setSideInclinedType] = useState<"NORMAL" | "INCLINED">("NORMAL");
  // currently selected border type
  // default: no border, no autofill
  const [borderType, setBorderTypeState] = useState<EditorBorderType>({} as EditorBorderType);
  // currently selected put-down strategy
  const [strategy, setStrategyState] = useState<string>(PUT_DOWN_STRATEGY_TYPES[1]);
  // the shape of the area - square / circle / triangle
  const [areaShape, setAreaShapeState] = useState<string>(AREA_SHAPE_TYPES[0]);
  // currently selected thickness, width & height
  const [thickness, setThicknessState] = useState<number>(0);
  const [width, setWidthState] = useState<number>(0);
  const [height, setHeightState] = useState<number>(0);
  // currenty editor zoom scale factor
  const [zoomScale, setZoomScaleState] = useState<{ x: number; y: number }>({ x: 1, y: 1 });
  // editor ref for export / saving / move reset
  const ref = useRef();
  // the size of the normal tyle (center for puzzle types)
  const [tileSize, setTileSize] = useState<TileSize>({
    width: 0,
    height: 0,
  });
  // side and corner sizes for puzzle types
  const [sideSize, setSideSize] = useState<TileSize>({
    width: 0,
    height: 0,
  });
  const [cornerSize, setCornerSize] = useState<TileSize>({
    width: 0,
    height: 0,
  });
  // the thickness variants / variant data for the currently selected item
  const [avaibleThickness, setAvaibleThickness] = useState<number[]>([]);
  const [avaibleVariants, setAvaibleVariants] = useState<Map<number, ItemVariant[]> | null>(null);
  // the avaible color variants for the currently selected item
  const [availableColors, setAvailableColors] = useState<ColorType[]>([]);
  // tile data (new)
  const [tileData, setTileDataState] = useState<TileData[][]>([]);
  // edit history
  const [editHistory, setEditHistory] = useState<SaveState[]>([]);
  // states for dialogs
  const [saveOpen, setSaveOpenState] = useState<boolean>(false);
  const [saveAsOpen, setSaveAsOpenState] = useState<boolean>(false);
  const [loadOpen, setLoadOpenState] = useState<boolean>(false);
  const [createOpen, setCreateOpenState] = useState<boolean>(false);
  const [offerOpen, setOfferOpenState] = useState<"OFFER" | "PDF" | null>(null);
  // save tracking code
  const [trackingCode, setTrackingCode] = useState<string>("");
  // sidebar toggle
  const [editorOpen, setEditorOpenState] = useState<boolean>(true);
  // offer statistics
  const [offerStats, setOfferStatsState] = useState<OfferStats | null>(null);
  // editor options
  // grid, mouse tracking, etc.
  const [editorOptions, setEditorOptionsState] = useState<EditorOptions>(defaultEditorOptions);

  // ghost tile data for inner side / corner placement
  const [ghostTile, setGhostTileState] = useState<TileData | undefined>(undefined);
  const [update, setUpdate] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // add history entry
  function addEditHistoryEntry(values: {
    putDownType?: string;
    borderType?: EditorBorderType;
    width?: number;
    height?: number;
    baseColor?: ColorType;
    strategy?: string;
    tileData?: TileData[][];
    areaShape?: string;
  }) {
    let entry = {
      putDownType: values.putDownType || putDownType,
      borderType: values.borderType || borderType,
      width: values.width || width,
      height: values.height || height,
      baseColor: values.baseColor || baseColor,
      strategy: values.strategy || strategy,
      itemId: itemId?.toString() || "",
      tileData: values.tileData || tileData,
      areaShape: values.areaShape || areaShape,
    };
    let history = editHistory.length >= HISTORY_STEP_LIMIT ? editHistory.slice(1) : editHistory;
    history.push(entry);
    setEditHistory(history);
  }

  // reset the previous state
  function historyStepBack() {
    if (isEmpty(editHistory)) {
      return;
    }

    let newArray = editHistory.slice(0, editHistory.length - 1);
    let lastState = editHistory[editHistory.length - 1];

    if (!!lastState) {
      setPutDownTypeState(lastState?.putDownType);
      setBorderTypeState(lastState?.borderType);
      setWidthState(lastState?.width);
      setHeightState(lastState?.height);
      setBaseColorState(lastState?.baseColor);
      setStrategyState(lastState.strategy);
      setTileDataState(lastState?.tileData);
      setAreaShapeState(lastState?.areaShape as string);
      setEditHistory(newArray);
    }
  }

  // saving & loading
  // save - last edit
  async function saveData(forceNew: boolean, openModal: boolean) {
    let newTrackingCode = trackingCode || "";
    try {
      let noEditFlag: boolean = false;

      if (editHistory.length === 0) {
        addEditHistoryEntry({});
        noEditFlag = true;
      }

      let json = JSON.stringify({
        itemId,
        areaShape,
        baseColor,
        borderType,
        height,
        putDownType,
        strategy,
        tileData,
        width,
        finalOffset: editorOptions.finalOffset,
        finalOffsetType: editorOptions.finalOffsetType,
        thickness,
        sideInclinedType,
      });

      if (!newTrackingCode) {
        const { data } = await saveNewSnapshot(json);
        setTrackingCode(data.item.trackingCode);
        newTrackingCode = data.item.trackingCode;
        navigate(`${window.location.pathname}?code=${data.item.trackingCode}`);
        if (openModal) {
          setSaveOpen(true);
        }
      } else if (forceNew) {
        const { data } = await saveNewSnapshot(json);
        setTrackingCode(data.item.trackingCode);
        navigate(`${window.location.pathname}?code=${data.item.trackingCode}`);
        if (openModal) {
          setSaveAsOpen(true);
        }
      } else {
        await saveSnapshot(newTrackingCode, json);
        if (openModal) {
          setSaveOpen(true);
        }
      }

      if (noEditFlag) {
        setEditHistory(editHistory.slice(0, -1));
      }

      let variants: OfferVariant[] = [];
      offerStats?.tileCountPerVariant.forEach(type =>
        type
          .filter(entry => entry.count > 0)
          .forEach(entry => {
            variants.push({
              itemVariant: {
                id: entry.variant?.id || 0,
              },
              color: {
                id: entry.color?.id,
              },
              quantity: entry.count,
            });
          }),
      );

      queryClient.refetchQueries({ queryKey: ["pdfQuery"] });

      enqueueSnackbar(t("common:notifications.snapshotSave.success"), { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar(t("common:notifications.snapshotSave.error"), { variant: "error" });
    }
    return newTrackingCode;
  }
  // load data
  async function loadData(trackingCode: string) {
    setLoadOpenState(false);
    try {
      setTrackingCode(trackingCode);
      if (trackingCode) {
        const { data } = await getByTrackingCode(trackingCode);
        const save = JSON.parse(data.item.data);

        setTileDataState(save.tileData);
        setBaseColorState(save.baseColor);
        setVariantPickerData({
          centerColor: save.baseColor,
          sideColor: save.baseColor,
          cornerColor: save.baseColor,
          centerCut: false,
          sideCut: false,
          cornerCut: false,
        });
        setBorderTypeState(save.borderType);
        setWidthState(save.width);
        setHeightState(save.height);
        setStrategyState(save.strategy);
        setPutDownTypeState(save.putDownType);
        setSideInclinedType(save.sideInclinedType);
        setAreaShapeState(save.areaShape);
        setItemId(save.itemId);
        setThickness(save.thickness);
        modifyEditorOption({ finalOffset: save.finalOffset, finalOffsetType: save.finalOffsetType });

        // enqueueSnackbar(t("common:notifications.snapshotLoad.success"), {
        //   variant: "success",
        // });
      }
    } catch {
      enqueueSnackbar(t("common:notifications.snapshotLoad.error"), {
        variant: "error",
      });
    }
  }

  // temporarily disabled -> not rendering after load
  useEffect(() => {
    if (defTrackingCode) {
      loadData(defTrackingCode);
    }
  }, [defTrackingCode]);

  // statistics for offer
  useEffect(() => {
    const centerVariant = avaibleVariants?.get(thickness)?.find(entry => entry.type === "CENTER");
    const borderVariant = itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "SIDE") : undefined;
    const borderAltVariant = itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "SIDE_ALT") : undefined;
    const borderInclinedVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "SIDE_INCLINED") : undefined;
    const borderInclinedAltVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "SIDE_INCLINED_ALT") : undefined;
    const cornerVariant = itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "CORNER") : undefined;
    const cornerCutVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "CORNER_INCLINED") : undefined;
    const innerCornerLeftTopVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "INNER_CORNER_LEFT_TOP") : undefined;
    const innerCornerLeftBottomVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "INNER_CORNER_LEFT_BOTTOM") : undefined;
    const innerCornerRightTopVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "INNER_CORNER_RIGHT_TOP") : undefined;
    const innerCornerRightBottomVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "INNER_CORNER_RIGHT_BOTTOM") : undefined;
    const innerCornerLeftTopInclinedVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "INNER_CORNER_LEFT_TOP_INCLINED") : undefined;
    const innerCornerLeftBottomInclinedVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "INNER_CORNER_LEFT_BOTTOM_INCLINED") : undefined;
    const innerCornerRightTopInclinedVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "INNER_CORNER_RIGHT_TOP_INCLINED") : undefined;
    const innerCornerRightBottomInclinedVariant =
      itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "INNER_CORNER_RIGHT_BOTTOM_INCLINED") : undefined;

    function getVariantByName(name: string): ItemVariant | undefined {
      switch (name) {
        case "center":
          return centerVariant;
        case "side":
          return borderVariant;
        case "side_alt":
          return borderAltVariant;
        case "side_inclined":
          return borderInclinedVariant;
        case "side_inclined_alt":
          return borderInclinedAltVariant;
        case "corner":
          return cornerVariant;
        case "corner_inclined":
          return cornerCutVariant;
        case "inner_corner_left_top":
          return innerCornerLeftTopVariant;
        case "inner_corner_left_bottom":
          return innerCornerLeftBottomVariant;
        case "inner_corner_right_top":
          return innerCornerRightTopVariant;
        case "inner_corner_right_bottom":
          return innerCornerRightBottomVariant;
        case "inner_corner_left_top_inclined":
          return innerCornerLeftTopInclinedVariant;
        case "inner_corner_left_bottom_inclined":
          return innerCornerLeftBottomInclinedVariant;
        case "inner_corner_right_top_inclined":
          return innerCornerRightTopInclinedVariant;
        case "inner_corner_right_bottom_inclined":
          return innerCornerRightBottomInclinedVariant;
      }
      return undefined;
    }

    const itemWeight = Number.parseFloat(centerVariant?.weight.toString() || "0");
    const sideWeight = Number.parseFloat(borderVariant?.weight.toString() || "0");
    const sideAltWeight = Number.parseFloat(borderAltVariant?.weight.toString() || "0");
    const sideInclinedWeight = Number.parseFloat(borderInclinedVariant?.weight.toString() || "0");
    const sideInclinedAltWeight = Number.parseFloat(borderInclinedAltVariant?.weight.toString() || "0");
    const cornerWeight = Number.parseFloat(cornerVariant?.weight.toString() || "0");
    const cornerInclinedWeight = Number.parseFloat(cornerCutVariant?.weight.toString() || "0");
    const innerCornerLeftTopWeight = Number.parseFloat(innerCornerLeftTopVariant?.weight.toString() || "0");
    const innerCornerLeftBottomWeight = Number.parseFloat(innerCornerLeftBottomVariant?.weight.toString() || "0");
    const innerCornerRightTopWeight = Number.parseFloat(innerCornerRightTopVariant?.weight.toString() || "0");
    const innerCornerRightBottomWeight = Number.parseFloat(innerCornerRightBottomVariant?.weight.toString() || "0");
    const innerCornerLeftTopInclinedWeight = Number.parseFloat(innerCornerLeftTopInclinedVariant?.weight.toString() || "0");
    const innerCornerLeftBottomInclinedWeight = Number.parseFloat(innerCornerLeftBottomInclinedVariant?.weight.toString() || "0");
    const innerCornerRightTopInclinedWeight = Number.parseFloat(innerCornerRightTopInclinedVariant?.weight.toString() || "0");
    const innerCornerRightBottomInclinedWeight = Number.parseFloat(innerCornerRightBottomInclinedVariant?.weight.toString() || "0");

    let allTileCount = 0;
    let mass = 0;
    let co2Saving = 0;
    //ITTT
    let tileCountPerVariant: Map<string, VariantColorData[]> = new Map<string, VariantColorData[]>();

    [
      "center",
      "side",
      "side_alt",
      "side_inclined",
      "side_inclined_alt",
      "corner",
      "corner_inclined",
      "inner_corner_left_top",
      "inner_corner_left_bottom",
      "inner_corner_right_top",
      "inner_corner_right_bottom",
      "inner_corner_left_top_inclined",
      "inner_corner_left_bottom_inclined",
      "inner_corner_right_top_inclined",
      "inner_corner_right_bottom_inclined",
    ].forEach(type => {
      tileCountPerVariant.set(
        type,
        availableColors.map(color => {
          return { variant: getVariantByName(type), color: color, count: 0 };
        }),
      );
    });

    //real size
    let boundingBox: BoundingBox = {
      x1: Number.MAX_VALUE,
      y1: Number.MAX_VALUE,
      x2: 0,
      y2: 0,
    };

    // size in m2 (only existing active tiles)
    let realM2: number = 0;

    tileData.forEach(row =>
      row.forEach(tile => {
        if (!tile.inactive && tile.transform.color !== "") {
          realM2 += getTileArea(tile, avaibleVariants, thickness, itemType);
          let tileBounds = getTileBounds(tile, centerVariant, cornerVariant, borderVariant);
          if (tileBounds?.x1 < boundingBox.x1) {
            boundingBox.x1 = tileBounds.x1;
          }
          if (tileBounds?.y1 < boundingBox.y1) {
            boundingBox.y1 = tileBounds.y1;
          }
          if (tileBounds?.x2 > boundingBox.x2) {
            boundingBox.x2 = tileBounds.x2;
          }
          if (tileBounds?.y2 > boundingBox.y2) {
            boundingBox.y2 = tileBounds.y2;
          }
        }
      }),
    );

    boundingBox.x1 = ceil(boundingBox.x1, 0);
    boundingBox.x2 = ceil(boundingBox.x2, 0);
    boundingBox.y1 = ceil(boundingBox.y1, 0);
    boundingBox.y2 = ceil(boundingBox.y2, 0);

    let corner: TileData = null!;
    let side: TileData = null!;
    let center: TileData = null!;

    tileData?.forEach(value => {
      corner = value.find(value2 => value2.category?.includes("corner")) || corner;
      side = value.find(value2 => value2.category?.includes("side")) || side;
      center = value.find(value2 => value2.category?.includes("center")) || center;
    });

    const horizontalPcs = calculateHorizontalPieces(getLeftmostTile(tileData), getRightmostTile(tileData), borderType);
    const verticalPcs = calculateVerticalPieces(getTopmostTile(tileData), getBottommostTile(tileData), borderType);

    let realWidth =
      (horizontalPcs.centerPcs * (center?.variant?.width || 0) + horizontalPcs.sidePcs * (side?.variant?.length || corner?.variant?.length || 0)) /
      100;

    let realHeight =
      (verticalPcs.centerPcs * (center?.variant?.width || 0) + verticalPcs.sidePcs * (side?.variant?.length || corner?.variant?.length || 0)) / 100;

    // helper function for determining the bounds of a given tile
    // tile counts
    tileData.forEach(row =>
      row.forEach((tile, index) => {
        let type: string = "center";
        if (tile.type === "center") {
          type = "center";
        }

        const sameTileElements = row?.filter(value => value.parentId && value.parentId === tile.parentId);
        const otherElement = sameTileElements.find(value => value.id !== tile.id);

        const otherElementIndex = row.findIndex(value => value.id !== tile?.id && value.parentId === tile.parentId);
        const isInnerCorner = sameTileElements?.length === 2;

        if (tile.type.startsWith("side") || tile.type.startsWith("inner_side")) {
          if (tile.type.includes("inclined")) {
            if (tile.position.rotation === 90) {
              if (isInnerCorner) {
                if (!otherElement?.position?.rotation) {
                  type = "inner_corner_left_bottom_inclined";
                } else if (otherElement?.position?.rotation === 180) {
                  type = "inner_corner_left_top_inclined";
                }
              } else {
                type = "side_inclined";
              }
            }

            if (tile.position.rotation === 180) {
              if (isInnerCorner) {
                if (otherElement?.position?.rotation === 90) {
                  type = "inner_corner_left_top_inclined";
                } else if (otherElement?.position?.rotation === -90) {
                  type = "inner_corner_right_top_inclined";
                }
              } else {
                type = "side_inclined";
              }
            }

            if (tile.position.rotation === -90) {
              if (isInnerCorner) {
                if (!otherElement?.position?.rotation) {
                  type = "inner_corner_right_bottom_inclined";
                } else if (otherElement?.position?.rotation === 180) {
                  type = "inner_corner_right_top_inclined";
                }
              } else {
                type = "side_inclined_alt";
              }
            }

            if (!tile.position.rotation) {
              if (isInnerCorner) {
                if (otherElement?.position?.rotation === -90) {
                  type = "inner_corner_right_bottom_inclined";
                } else if (otherElement?.position?.rotation === 90) {
                  type = "inner_corner_left_bottom_inclined";
                }
              } else {
                type = "side_inclined_alt";
              }
            }
          } else {
            if (tile.position.rotation === 90) {
              if (isInnerCorner) {
                if (!otherElement?.position?.rotation) {
                  type = "inner_corner_left_bottom";
                } else if (otherElement?.position?.rotation === 180) {
                  type = "inner_corner_left_top";
                }
              } else {
                type = "side";
              }
            }

            if (tile.position.rotation === 180) {
              if (isInnerCorner) {
                if (otherElement?.position?.rotation === 90) {
                  type = "inner_corner_left_top";
                } else if (otherElement?.position?.rotation === -90) {
                  type = "inner_corner_right_top";
                }
              } else {
                type = "side";
              }
            }

            if (tile.position.rotation === -90) {
              if (isInnerCorner) {
                if (!otherElement?.position?.rotation) {
                  type = "inner_corner_right_bottom";
                } else if (otherElement?.position?.rotation === 180) {
                  type = "inner_corner_right_top";
                }
              } else {
                type = "side_alt";
              }
            }

            if (!tile.position.rotation) {
              if (isInnerCorner) {
                if (otherElement?.position?.rotation === -90) {
                  type = "inner_corner_right_bottom";
                } else if (otherElement?.position?.rotation === 90) {
                  type = "inner_corner_left_bottom";
                }
              } else {
                type = "side_alt";
              }
            }
          }
        }

        if (tile.type.startsWith("corner") || tile.type.startsWith("inner_corner")) {
          if (tile.type.includes("inclined")) {
            type = "corner_inclined";
          } else {
            type = "corner";
          }
        }

        // counting the tiles grouped by variants and color
        if (!tile.inactive && tile.transform.color) {
          if ((otherElementIndex < index && otherElementIndex >= 0 && isInnerCorner) || !isInnerCorner) {
            allTileCount++;
            mass += getTileMass(type);
            let counter: VariantColorData[] | undefined = tileCountPerVariant.get(type);
            let variantEntry = counter?.find(entry => entry.color?.id.toString() === tile.transform.texture);
            if (!!variantEntry) {
              variantEntry.count++;
            }
          }
        }
      }),
    );

    // CO2
    co2Saving = round(mass * 0.9 * 0.7, 2);

    // sorting arrays for optimal display
    tileCountPerVariant.forEach(group => {
      group.sort((a, b) => (a.count > b.count ? -1 : a.count < b.count ? 1 : 0));
    });

    // final rounding of the coverage m2 value
    realM2 = round(realM2, 2);

    setOfferStatsState({
      allTileCount,
      mass,
      co2Saving,
      tileCountPerVariant,
      realWidth,
      realHeight,
      realM2,
    });

    //ITTT
    // get the mass of the given tile based on type
    function getTileMass(type: string): number {
      switch (type) {
        case "center":
          return itemWeight;
        case "side":
          return sideWeight;
        case "side_alt":
          return sideAltWeight;
        case "side_inclined":
          return sideInclinedWeight;
        case "side_inclined_alt":
          return sideInclinedAltWeight;
        case "corner":
          return cornerWeight;
        case "corner_inclined":
          return cornerInclinedWeight;
        case "inner_corner_left_top":
          return innerCornerLeftTopWeight;
        case "inner_corner_left_bottom":
          return innerCornerLeftBottomWeight;
        case "inner_corner_right_top":
          return innerCornerRightTopWeight;
        case "inner_corner_right_bottom":
          return innerCornerRightBottomWeight;
        case "inner_corner_left_bottom_inclined":
          return innerCornerLeftBottomInclinedWeight;
        case "inner_corner_left_top_inclined":
          return innerCornerLeftTopInclinedWeight;
        case "inner_corner_right_bottom_inclined":
          return innerCornerRightBottomInclinedWeight;
        case "inner_corner_right_top_inclined":
          return innerCornerRightTopInclinedWeight;
      }
      return 0;
    }
  }, [tileData, thickness, borderType, update, avaibleVariants, itemType, availableColors]);
  // }, [tileData, thickness, borderType, update]);

  // function for resetting to "base state"
  // the "base state" changes with size, area shape, base color etc...
  // for that reason, it only reverts: (for now)
  //     tile coloring and / or deletion
  //     all inner tile placement
  //     all inclined tiles back to normal
  // also flips the isEdited flag to false
  function resetEditChanges() {
    let temp = [...tileData];
    // delete inner tiles
    temp[temp.length - 1] = [];
    // recolor + isEdited flag flip
    temp = temp.map(row =>
      row.map(entry => {
        return {
          ...entry,
          type:
            sideInclinedType === "INCLINED"
              ? !entry.type.includes("_inclined") && !entry.type?.includes("center")
                ? entry.type + "_inclined"
                : entry.type
              : entry.type.replace("_inclined", ""),
          transform: {
            ...entry.transform,
            color: entry.inactive ? "" : baseColor?.color || "",
            texture: entry.inactive ? undefined : baseColor?.id.toString(),
          },
          isEdited: false,
          variant: avaibleVariants
            ?.get(thickness)
            ?.find(
              entry =>
                entry.type ===
                (entry.type.includes("side")
                  ? itemVariantTypes.side
                  : entry.type.includes("corner")
                  ? itemVariantTypes.corner
                  : itemVariantTypes.center),
            ),
        };
      }),
    );
    setTileData(true, temp);
  }

  // editor options
  function modifyEditorOption(values: Partial<EditorOptions>) {
    let newState = {
      planBorder: values.planBorder !== undefined ? values.planBorder : editorOptions.planBorder,
      planBorderColor: values.planBorderColor || editorOptions.planBorderColor,
      planBorderSize: values.planBorderSize || editorOptions.planBorderSize,
      showGrid: values.showGrid !== undefined ? values.showGrid : editorOptions.showGrid,
      gridColor: values.gridColor || editorOptions.gridColor,
      gridSize: values.gridSize || editorOptions.gridSize,
      tracking: values.tracking !== undefined ? values.tracking : editorOptions.tracking,
      trackingColor: values.trackingColor || editorOptions.trackingColor,
      trackingSize: values.trackingSize || editorOptions.trackingSize,
      trackingText: values.trackingText !== undefined ? values.trackingText : editorOptions.trackingText,
      trackingTextColor: values.trackingTextColor || editorOptions.trackingTextColor,
      finalOffset: values.finalOffset !== undefined ? values.finalOffset : editorOptions.finalOffset,
      finalOffsetType: values.finalOffsetType || editorOptions.finalOffsetType,
      backgroundColor: values.backgroundColor || editorOptions.backgroundColor,
    };
    setEditorOptionsState(newState);
    sessionStorage.setItem("GumTech_Editor_Options", JSON.stringify(newState));
  }

  function resetEditorOptions() {
    setEditorOptionsState(defaultEditorOptions);
    sessionStorage.removeItem("GumTech_Editor_Options");
  }

  function getFinalOffset(isHorizontal: boolean) {
    //horizontal
    if (isHorizontal) {
      return editorOptions.finalOffset && editorOptions.finalOffsetType !== FINAL_PLACEMENT_TYPES[2];
    }
    //vertical
    return editorOptions.finalOffset && editorOptions.finalOffsetType !== FINAL_PLACEMENT_TYPES[1];
  }

  //for loading existing options
  useEffect(() => {
    const savedOptionsJSON = sessionStorage.getItem("GumTech_Editor_Options");
    const savedOptions: EditorOptions = savedOptionsJSON ? JSON.parse(savedOptionsJSON) : undefined;
    if (savedOptions) {
      modifyEditorOption(savedOptions);
    }
  }, []); //eslint-disable-line

  // setter functions
  function setMouseMode(mode: string) {
    setMouseModeState(mode);
  }
  function setActiveColor(color: ColorType | null) {
    setActiveColorState(color);
  }
  function setBaseColor(color: ColorType | null) {
    setBaseColorState(color);
    if (!!baseColor) {
      addEditHistoryEntry({ baseColor: baseColor });
    }
  }
  function setPutDownType(type: string) {
    setPutDownTypeState(type);
    addEditHistoryEntry({ putDownType: putDownType });
  }
  function setBorderType(nborder: Partial<EditorBorderType>, needHistory: boolean) {
    let newBorder = {
      left: nborder.left !== undefined ? nborder.left : borderType.left,
      right: nborder.right !== undefined ? nborder.right : borderType.right,
      top: nborder.top !== undefined ? nborder.top : borderType.top,
      bottom: nborder.bottom !== undefined ? nborder.bottom : borderType.bottom,
    };
    setBorderTypeState(newBorder);
    if (needHistory) {
      addEditHistoryEntry({ borderType: borderType });
    }
  }
  function setStrategy(newStrategy: string, needHistory: boolean) {
    setStrategyState(newStrategy);
    if (needHistory) {
      addEditHistoryEntry({ strategy: strategy });
    }
  }
  function setAreaShape(shape: string) {
    setAreaShapeState(shape);
    addEditHistoryEntry({ areaShape: areaShape });
    // TODO : add history entry
  }
  function setThickness(value: number) {
    setThicknessState(value);
  }
  function setAreaSize(pwidth: number, pheight: number, needHistory: boolean) {
    setWidthState(pwidth);
    setHeightState(pheight);
    if (needHistory) {
      addEditHistoryEntry({ width: width, height: height });
    }
  }
  function setWidth(value: number) {
    setWidthState(value);
  }
  function setHeight(value: number) {
    setHeightState(value);
  }
  function setZoomScale(value: { x: number; y: number }) {
    setZoomScaleState({
      x: value.x > ZOOM_LIMIT ? ZOOM_LIMIT : value.x,
      y: value.y > ZOOM_LIMIT ? ZOOM_LIMIT : value.y,
    });
  }
  function setSaveOpen(value: boolean) {
    setSaveOpenState(value);
  }

  function setSaveAsOpen(value: boolean) {
    setSaveAsOpenState(value);
  }
  function setLoadOpen(value: boolean) {
    setLoadOpenState(value);
  }
  function setCreateOpen(value: boolean) {
    setCreateOpenState(value);
  }
  function setEditorOpen(value: boolean) {
    setEditorOpenState(value);
  }
  function setOfferOpen(value: "OFFER" | "PDF" | null) {
    setOfferOpenState(value);
  }

  function setTilePlacementType(type: string) {
    setTilePlacementTypeState(type);
  }
  function setTileData(addToHistory: boolean, data: TileData[][]) {
    setUpdate(!update);
    setTileDataState(data);
    if (addToHistory) {
      addEditHistoryEntry({ tileData: tileData });
    }
  }

  function setVariantPickerData(data: VariantPickerDataType) {
    setVariantPickerDataState(data);
  }

  function selectItem(id: number) {
    setItemId(id);
  }

  //other functions
  async function resetCanvasMove() {
    const scaleX = (window.innerWidth - 400) / (width * pxPerMeter + 100);
    const scaleY = (window.innerHeight - 180) / (height * pxPerMeter + 100);
    const newScale = scaleX > scaleY ? scaleY : scaleX;
    (ref.current as any)?.absolutePosition({ x: 0, y: 0 });
    await setZoomScale({ x: newScale, y: newScale });
  }

  function resetEditor(values?: {
    borderType?: EditorBorderType;
    width?: number;
    height?: number;
    strategy?: string;
    putDownType?: string;
    itemId?: number;
    thickness?: number;
    color?: ColorType;
    sideInclinedType?: "NORMAL" | "INCLINED";
  }) {
    setTileDataState([]);
    setTileSize({ width: 0, height: 0 });
    setSideSize({ width: 0, height: 0 });
    setCornerSize({ width: 0, height: 0 });
    setBorderType(values?.borderType || ({} as EditorBorderType), false);
    setWidthState(values?.width || 10);
    setHeightState(values?.height || 10);
    setSideInclinedType(values?.sideInclinedType || "NORMAL");
    setStrategyState(values?.strategy || PUT_DOWN_STRATEGY_TYPES[0]);
    setPutDownTypeState(values?.putDownType || PUT_DOWN_TYPES[0]);
    setThicknessState(values?.thickness || thickness);
    setBaseColorState(values?.color || baseColor);
    setAreaShapeState(AREA_SHAPE_TYPES[0]);
    setEditorOptionsState({ ...editorOptions, finalOffset: true });

    setVariantPickerDataState({
      centerColor: values?.color || baseColor || undefined,
      sideColor: values?.color || baseColor || undefined,
      cornerColor: values?.color || baseColor || undefined,
      centerCut: false,
      sideCut: values?.sideInclinedType === "INCLINED",
      cornerCut: values?.sideInclinedType === "INCLINED",
    });

    setEditHistory([]);

    setItemId(values?.itemId || itemId);
    itemQuery.refetch();
    //resetCanvasMove();
  }

  function hasHistory() {
    return !isEmpty(editHistory);
  }

  function startOfferCreate(type: "OFFER" | "PDF") {
    resetCanvasMove();
    setOfferOpenState(type);
  }

  // function to change the ghost tile item when needed
  function setGhostTile(newGhost?: TileData) {
    setGhostTileState(newGhost);
  }

  return (
    <EditorContext.Provider
      value={{
        itemId,
        itemName,
        mouseMode,
        tilePlacementType,
        activeColor,
        putDownType,
        borderType,
        areaShape,
        strategy,
        thickness,
        width,
        height,
        zoomScale,
        ref,
        avaibleThickness,
        avaibleVariants,
        baseColor,
        availableColors,
        cutSize,
        tileSize,
        sideSize,
        cornerSize,
        tileData,
        saveOpen,
        saveAsOpen,
        loadOpen,
        createOpen,
        editorOpen,
        offerOpen,
        trackingCode,
        offerStats,
        editorOptions,
        itemType,
        variantPickerData,
        ghostTile,
        refresh,
        editHistory,
        sideInclinedType,
        setEditHistory,
        setRefresh,
        setMouseMode,
        setTilePlacementType,
        setActiveColor,
        setBaseColor,
        setPutDownType,
        setBorderType,
        setAreaShape,
        setStrategy,
        setThickness,
        setAreaSize,
        setWidth,
        setHeight,
        setZoomScale,
        resetCanvasMove,
        setTileData,
        hasHistory,
        historyStepBack,
        saveData,
        loadData,
        setSaveOpen,
        setSaveAsOpen,
        setLoadOpen,
        setCreateOpen,
        setOfferOpen,
        setEditorOpen,
        selectItem,
        startOfferCreate,
        resetEditor,
        modifyEditorOption,
        getFinalOffset,
        resetEditorOptions,
        setVariantPickerData,
        setGhostTile,
        resetEditChanges,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};
