import { Box, IconButton, Slider, Typography } from "@mui/material";
import { LINE_SIZE_MAX, LINE_SIZE_MIN } from "config/constants";
import { COLORS } from "config/theme";
import { ReactNode } from "react";
import { useEditorData } from "./EditorContext";

type Props = {
  title: ReactNode;
  target: string;
};

const LinePicker = ({ title, target }: Props) => {
  const { editorOptions, modifyEditorOption } = useEditorData();

  const data = () => {
    switch (target) {
      case "planBorderSize":
        return editorOptions.planBorderSize;
      case "gridSize":
        return editorOptions.gridSize;
      case "trackingSize":
        return editorOptions.trackingSize;
      default:
        return undefined;
    }
  };

  const setValue = (value: number) => {
    let limitedValue = value > LINE_SIZE_MIN ? (value < LINE_SIZE_MAX ? value : LINE_SIZE_MAX) : LINE_SIZE_MIN;
    switch (target) {
      case "planBorderSize":
        modifyEditorOption({ planBorderSize: limitedValue });
        break;
      case "gridSize":
        modifyEditorOption({ gridSize: limitedValue });
        break;
      case "trackingSize":
        modifyEditorOption({ trackingSize: limitedValue });
        break;
    }
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue((newValue || 0) as number);
  };

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      maxWidth="250px"
      sx={{
        height: "40px",
        border: `1px solid ${COLORS.greyBorder}`,
        borderRadius: "2px",
        padding: "8px",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: COLORS.lightBlue,
        }}
      >
        {title}
      </Typography>
      <Box display="flex" width="120px" gap={1}>
        <IconButton size="small" onClick={() => setValue((data() || 0) - 1)} disabled={data() === 0}>
          <Box
            width="4px"
            height="4px"
            borderRadius="50%"
            sx={{
              backgroundColor: COLORS.greyLightBorder,
            }}
          />
        </IconButton>
        <Slider
          size="small"
          value={data() || 0}
          onChange={handleChange}
          step={1}
          marks
          min={0}
          max={10}
          sx={{
            height: "4px",
          }}
        />
        <IconButton size="small" onClick={() => setValue((data() || 10) + 1)} disabled={data() === 10}>
          <Box
            width="12px"
            height="12px"
            borderRadius="50%"
            sx={{
              backgroundColor: COLORS.greyLightBorder,
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default LinePicker;
