import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { Item } from "shared/types";

const ENDPOINT = "/item";

export const listItems = (page: number, size: number, search: string = "", sort: string = "") =>
  axios.get<GenericPageResponse<Item>>(`${ENDPOINT}/get-page?page=${page}&size=${size}&search=${search}&sort=${sort}`); //ITEM_GET

export const getItemById = (param: number) =>
  axios.post<GenericResponse<any>>(`${ENDPOINT}/get-by-id`, {
    param,
  });

export const createItem = (param: any, password: string) => axios.post(`${ENDPOINT}/create`, { ...param, password });

export const modifyItem = (param: any, password: string) => axios.put(`${ENDPOINT}/modify`, { ...param, password });

export const inactivateItem = (param: number, password: string) => axios.post(`${ENDPOINT}/inactivate`, { param, password });
