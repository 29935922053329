import { EditorBorderType, TileCalculateData } from "../components/EditorContext";

export function calculateHorizontalPieces(leftMost: TileCalculateData, rightMost: TileCalculateData, borderType: EditorBorderType) {
  let sidePcs = 0;
  let centerPcs = 0;
  if (
    borderType.left &&
    (leftMost?.tile?.type?.includes("side_left") ||
      leftMost?.tile?.type?.includes("corner_topleft") ||
      leftMost?.tile?.type?.includes("corner_bottomleft"))
  ) {
    sidePcs++;
  }

  if (
    borderType.right &&
    (rightMost?.tile?.type?.includes("side_right") ||
      rightMost?.tile?.type?.includes("corner_topright") ||
      rightMost?.tile?.type?.includes("corner_bottomright"))
  ) {
    sidePcs++;
  }

  if (rightMost && leftMost) {
    centerPcs = rightMost.x - sidePcs - (leftMost.x - 1);

    if (leftMost?.child) {
      if (leftMost?.child.position.rotation !== 0 && leftMost?.child.position.rotation !== 180 && leftMost.tile.type === "center") {
        centerPcs--;
        sidePcs++;
      }
    }
    if (rightMost?.child) {
      if (rightMost?.child.position.rotation !== 0 && rightMost?.child.position.rotation !== 180 && rightMost.tile.type === "center") {
        centerPcs--;
        sidePcs++;
      }
    }
  }
  return { sidePcs, centerPcs };
}
