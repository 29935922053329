import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, Divider, Grid, Tabs, Typography, useMediaQuery } from "@mui/material";
import StyledDialogHeader from "components/Layout/StyledDialogTitle";
import StyledTab from "components/Layout/StyledTab";
import theme, { COLORS } from "config/theme";
import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import ColorPicker from "./ColorPicker";
import { useEditorData } from "./EditorContext";
import LinePicker from "./LinePicker";
import ResetConfirmationDialog from "./ResetConfirmationDialog";
import SettingsCheckBox from "./SettingsCheckBox";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const EditorSettingsDialog = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const editorData = useEditorData();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      sx={{
        backdropFilter: "blur(5px)",
        backgroundColor: "rgba(38, 82, 100, 0.7)",
      }}
      hideBackdrop
      onClose={() => setOpen(false)}
    >
      <StyledDialogHeader title={t("editor.options.title")} setOpen={setOpen} />
      <AppBar
        position="static"
        elevation={0}
        sx={{
          borderBottom: `1px solid ${COLORS.greyA}`,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
          sx={{
            color: COLORS.foskek,
            backgroundColor: "white",
          }}
        >
          <StyledTab label={t("adjustment.tab1")} />
          <StyledTab label={t("adjustment.tab2")} />
          <StyledTab label={t("adjustment.tab3")} />
        </Tabs>
      </AppBar>
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: 350,
        }}
      >
        {value === 0 && (
          <Grid container justifyContent="center" sx={{}}>
            <Grid item xs={12} sm={5}>
              <Box width="250px" margin="auto">
                <Box pb="20px" margin="auto">
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.foskek,
                    }}
                  >
                    {t("adjustment.border")}
                  </Typography>
                </Box>
                <Box pb="30px">
                  <SettingsCheckBox title={t("editor.options.planArea.toggle")} target="planBorder" />
                </Box>
                <Box pb="30px">
                  <ColorPicker title={t("editor.options.planArea.color")} target="planBorderColor" />
                </Box>
                <Box pb="30px">
                  <LinePicker title={t("editor.options.planArea.line")} target="planBorderSize" />
                </Box>
              </Box>
            </Grid>
            {matches && (
              <Grid item xs={12} sm={1}>
                <Box height="100%" display="flex" justifyContent="center">
                  <Divider orientation="vertical" flexItem />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={5}>
              <Box width="250px" margin="auto">
                <Box pb="20px" margin="auto">
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.foskek,
                    }}
                  >
                    {t("adjustment.background")}
                  </Typography>
                </Box>
                <Box pb="30px">
                  <ColorPicker title={t("editor.options.background.color")} target="backgroundColor" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
        {value === 1 && (
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={5}>
              <Box width="250px" margin="auto">
                <Box pb="30px">
                  <SettingsCheckBox title={t("editor.options.grid.toggle")} target="showGrid" />
                </Box>
                <Box pb="30px">
                  <ColorPicker title={t("editor.options.grid.color")} target="gridColor" />
                </Box>
                <Box pb="30px">
                  <LinePicker title={t("editor.options.grid.line")} target="gridSize" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
        {value === 2 && (
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={5}>
              <Box width="250px" margin="auto">
                <Box pb="20px" margin="auto">
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.foskek,
                    }}
                  >
                    {t("adjustment.cursorTracking")}
                  </Typography>
                </Box>
                <Box pb="30px">
                  <SettingsCheckBox title={t("editor.options.tracking.toggle")} target="tracking" />
                </Box>
                <Box pb="30px">
                  <ColorPicker title={t("editor.options.tracking.color")} target="trackingColor" />
                </Box>
                <Box pb="30px">
                  <LinePicker title={t("editor.options.tracking.line")} target="trackingSize" />
                </Box>
              </Box>
            </Grid>
            {matches && (
              <Grid item xs={12} sm={1}>
                <Box height="100%" display="flex" justifyContent="center">
                  <Divider orientation="vertical" flexItem />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={5}>
              <Box width="250px" margin="auto">
                <Box pb="20px" margin="auto">
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: COLORS.foskek,
                    }}
                  >
                    {t("adjustment.pointerPosition")}
                  </Typography>
                </Box>
                <Box pb="30px">
                  <SettingsCheckBox title={t("editor.options.tracking.textToggle")} target="trackingText" />
                </Box>
                <Box pb="30px">
                  <ColorPicker title={t("editor.options.tracking.textColor")} target="trackingTextColor" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Box display="flex" justifyContent="space-between" width="100%" gap={1}>
          <Button
            variant="text"
            color="primary"
            sx={{
              minWidth: "150px",
              height: "45px",
              color: COLORS.redText,
            }}
            onClick={() => setAlertOpen(true)}
          >
            {t("common:buttons.reset")}
          </Button>
          <Box display="flex" gap={1}>
            <Button
              variant="text"
              color="primary"
              sx={{
                minWidth: "150px",
                height: "45px",
              }}
              onClick={() => setOpen(false)}
            >
              {t("common:buttons.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                minWidth: "150px",
                height: "45px",
              }}
              onClick={() => setOpen(false)}
            >
              {t("common:buttons.ok")}
            </Button>
          </Box>
        </Box>
      </DialogActions>
      <ResetConfirmationDialog type="settings" alertOpen={alertOpen} setAlertOpen={setAlertOpen} onSubmit={editorData.resetEditorOptions} />
    </Dialog>
  );
};

export default EditorSettingsDialog;
