import { ITEM_TYPES } from "config/constants";
import { ItemVariant } from "shared/types";
import { TileData } from "../EditorNew";

// helper function for detemining the area of the given tile based on type
export function getTileArea(tile: TileData, avaibleVariants: Map<number, ItemVariant[]> | null, thickness: number, itemType: string) {
  let variant: ItemVariant | undefined = undefined;
  if (tile.type === "center") {
    variant = avaibleVariants?.get(thickness)?.find(entry => entry.type === "CENTER");
  } else if (tile.type.startsWith("side") || tile.type.startsWith("inner_side")) {
    variant = itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "SIDE") : undefined;
  } else if (tile.type.startsWith("corner") || tile.type.startsWith("inner_corner")) {
    variant = itemType === ITEM_TYPES[1] ? avaibleVariants?.get(thickness)?.find(entry => entry.type === "CORNER") : undefined;
  }

  let width = (variant?.width || 0) / 100;
  let height = (variant?.length || 0) / 100;
  // if a side is cut, remove the cut area (triangle)
  if ((tile.type.startsWith("side") || tile.type.startsWith("inner_side")) && tile.type.includes("cut")) {
    return width * height - (height * height) / 2;
  }
  return width * height;
}
