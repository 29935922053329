import { TileCalculateData } from "../components/EditorContext";
import { TileData } from "../EditorNew";

export function getRightmostTile(tiles: TileData[][]) {
  const rowLength = tiles[0]?.length || tiles[1]?.length;
  let rightMostTile: TileCalculateData = null!;
  for (let j = rowLength - 1; j >= 0; j--) {
    for (let i = 0; i < tiles?.length - 1; i++) {
      if (!tiles[i][j]?.inactive && tiles[i][j]?.transform?.color && !rightMostTile) {
        rightMostTile = { tile: tiles[i][j], y: i + 1, x: j + 1 };
        break;
      }
      if (rightMostTile) {
        break;
      }
    }
  }

  let max = 0;
  let tile: TileData = null!;
  tiles[tiles?.length - 1]?.forEach(value => {
    if (value.position.posX > max) {
      tile = value;
      max = value.position.posX;
    }
  });

  let child: TileData = null!;
  let parent: TileCalculateData = null!;
  if (tile?.position.posX > rightMostTile?.tile?.position?.posX) {
    child = tile;
    for (let j = rowLength - 1; j >= 0; j--) {
      for (let i = 0; i < tiles?.length - 1; i++) {
        if (tiles[i][j]?.id === child?.parentId && !parent) {
          parent = { tile: tiles[i][j], y: i + 1, x: j + 1 };
          break;
        }
        if (parent) {
          break;
        }
      }
    }
  }

  if (parent) {
    return { ...parent, child };
  } else {
    return { ...rightMostTile, child };
  }
}
