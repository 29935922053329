import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import StyledDialogHeader from "components/Layout/StyledDialogTitle";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  type: string; //settings (editor settings) / edits (editor steps)
  alertOpen: boolean;
  setAlertOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: any;
};

const ResetConfirmationDialog = ({ type, alertOpen, setAlertOpen, onSubmit }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={alertOpen} onClose={() => setAlertOpen(false)} maxWidth="sm" fullWidth>
      <StyledDialogHeader title={t("resetDialog.mainTitle")} setOpen={setAlertOpen} />
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="18px"
          minHeight="250px"
          maxWidth="280px"
          margin="auto"
          sx={{ textAlign: "center" }}
        >
          <img width="60px" src={process.env.PUBLIC_URL + "iconQuestion.svg"} alt="question" />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            {t("resetDialog.title")}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: 2,
            }}
          >
            {t("resetDialog.body", { subject: t(`resetDialog.type.${type}`) })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Box display="flex" gap={1}>
          <Button
            sx={{
              minWidth: "150px",
              height: "45px",
            }}
            variant="text"
            onClick={() => setAlertOpen(false)}
          >
            {t("common:buttons.cancel")}
          </Button>
          <Button
            sx={{
              minWidth: "150px",
              height: "45px",
            }}
            variant="contained"
            onClick={() => {
              onSubmit();
              setAlertOpen(false);
            }}
          >
            {t("common:buttons.ok")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ResetConfirmationDialog;
