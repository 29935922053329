import { trim } from "lodash";
import { useSnackbar } from "notistack";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkPermissionRequest } from "shared/network/authentication-api";

type Props = {
  children: React.ReactNode;
};

type PermissionContextType = {
  hasPermission: boolean;
  passwordOpen: boolean;
  setPasswordOpen: (value: boolean) => void;
  checkPermission: (password: string) => void;
};

export const PermissionContext = createContext({} as PermissionContextType);

export function usePermissionData() {
  const context = useContext(PermissionContext);

  return context;
}

export const PermissionProvider = ({ children }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [hasPermission, setHasPermission] = useState<boolean>(sessionStorage.getItem("permission") === "true");
  const [passwordOpen, setPasswordOpenState] = useState<boolean>(false);

  function setPasswordOpen(value: boolean) {
    setPasswordOpenState(value);
  }

  async function checkPermission(password: string) {
    try {
      await checkPermissionRequest(trim(password));
      setHasPermission(true);
      sessionStorage.setItem("permission", "true");
      sessionStorage.setItem("password", trim(password));
      setPasswordOpenState(false);
      enqueueSnackbar(t("common:notifications.offerSend.success"), { variant: "success" });
    } catch (e) {
      enqueueSnackbar(t("common:notifications.offerSend.error"), { variant: "error" });
    }
  }

  return (
    <PermissionContext.Provider
      value={{
        hasPermission,
        passwordOpen,
        setPasswordOpen,
        checkPermission,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};
