import { StrictMode, Suspense } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { CssBaseline, ThemeProvider } from "@mui/material";
import queryClient from "./config/query";
import PageLoading from "./components/PageLoading";
import ScrollToTop from "./components/ScrollToTop";
import { QueryClientProvider } from "@tanstack/react-query";
import theme from "./config/theme";
import store from "config/store";
import "./i18n";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider>
              <Suspense fallback={<PageLoading />}>
                <ScrollToTop />
                <App />
              </Suspense>
            </SnackbarProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>,
);
